
import React, { useState } from 'react';
import Button from 'reactstrap/lib/Button';
import moment from 'moment';
import { InputNumber } from 'primereact/inputnumber';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { generateListForReseive } from './functions'
import { UpdateItems, GenerateComprobantDelivery, CreateItems } from '../../../store/directusSdk';
import { toast } from 'react-toastify';

const ReceiveMaterialsModal = ({ open, setOpen, project, table, currentUser, update, deliver }) => {
    const [line, setLine] = useState([]);
    const [loading, setLoading] = useState(false);

    const renderQtyDelivered = (row) => {
 
            return row.delivered_qty;
       


        if (deliver) {
            const value = row.movements.reduce((acc, current) => {
                if (current.type === "delivery") {
                    acc += current.delivered_qty;

                    return acc;
                }

                acc -= current.delivered_qty;
                return acc;
            }, 0);


            return value;
        }

        return row.movements.reduce((acc, current) => {
            if (current.confirmed_qty) {
                acc += current.confirmed_qty;

                return acc;
            }
        }, 0)
    }

    const updateLines = (row, value) => {
        setLine((e) => {
            const newState = [...e];

            const lineOld = newState.findIndex((e) => e.id === row.id);
            if (lineOld < 0) {
                newState.push({ id: row.id, confirmed_qty: value, engprojectline_id: row.product.id })
                return newState;
            }

            newState[lineOld] = { id: row.id, confirmed_qty: value, engprojectline_id: row.product.id };

            return newState;
        })
    }

    const renderQtyReceive = (row) => {
       

        return <InputNumber
            value={row.confirmed_qty ?? 0}
            min={0}
            max={renderQtyDelivered(row)}
            showButtons
            onValueChange={(e) => updateLines(row, e.value)}
            buttonLayout="horizontal"
            decrementButtonClassName="p-button-secondary"
            incrementButtonClassName="p-button-info"
            incrementButtonIcon="pi pi-plus"
            decrementButtonIcon="pi pi-minus"
            size={1}
        />
    }

    const savedChange = () => {
        setLoading(true)
        const sendLines = line.map((e) => ({
            ...e,
            confirmed_by: currentUser.id,
            confirmed_date: moment().format('YYYY-MM-DD HH:mm:ss'),
        }))

        Promise.all([table === 'ICA' ?
            UpdateItems('engprojectlinesmovementsica', sendLines) :
            UpdateItems('engprojectlinesmovementsap', sendLines)
        ]).then((res) => {
            update && update()
            toast.success('Materiales Recibidos ');

        }).catch((error) => toast.error(`¡Error!, ${error.message}`))
            .finally(() => {
                setLoading(false)
                setOpen(false)
            });
    }

    const sendReturns = () => {
        setLoading(true)
        const itemsForCollection = [];
        const itemsForPdf = [];
        const rows = generateListForReseive(project, table, deliver);

        const format = rows.reduce((acc, current) => {
            const r = acc.find((e) => e.project.code === current.project.code);
            if (!r) {
                acc.push(current);
            }
            return acc;
        }, []);

        line.forEach((l) => {
            itemsForCollection.push({
                delivered_date: moment().format('YYYY-MM-DD HH:mm:ss'),
                type: "return",
                engprojectline_id: l.engprojectline_id,
                delivered_qty: l.confirmed_qty,
                delivered_by: currentUser.id,
            });

         
            const row = rows.find((e) => e.product.id === l.engprojectline_id);
            itemsForPdf.push({
                warehouse: row.product.warehouse,
                code: row.product.code,
                name: row.product.name,
                orderQty: row.product.order,
                delivered_qty: l.confirmed_qty,
                pry: row.project.code
            })
        });


        const order = format.map((fx) => {
            return ({
                items: itemsForPdf.filter((e) => e.pry === fx.project.code),
                company: table,
                pry_code: fx.project.code,
                pry_name: fx.project.name
            })
        })
        Promise.all([
            CreateItems(table === 'ICA' ? 'engprojectlinesmovementsica' : 'engprojectlinesmovementsap', itemsForCollection),
            ...order.map((i) => i.items.length ? GenerateComprobantDelivery('returns', i) : false),
        ]).then(() => {
            update();
            setOpen(false)
            toast.success('¡Devoluciones Realizados con Exito!')
        })
            .catch((error) => toast.error(`¡Error!, ${error.message}`))
            .finally(() => setLoading(false));
    };

    return (
        <Modal isOpen={open} centered size="xl" zIndex={999}>
            <ModalHeader toggle={() => setOpen(false)}>
                {deliver ? 'Listado de materiales para recibir.' :
                    'Listado de materiales para devolver.'
                }
            </ModalHeader>
            <ModalBody>
                <DataTable value={generateListForReseive(project, table, deliver)} className="p-datatable-sm">
                    <Column header="Proyecto" body={(row) => row.project.code} />
                    <Column header="Codigo" body={(row) => row.product.code} />
                    <Column header={deliver ? "Cant. Disponible" : "Cant. Recibida"} body={renderQtyDelivered} />
                    <Column header={deliver ? "Cant. Recibir" : "Cant. Retornar"} body={renderQtyReceive} />
                </DataTable>
                <div className='p-2' style={{ display: 'flex', justifyContent: 'end' }}>
                    <Button
                        type="button"
                        className="btn btn-primary mr-3"
                        disabled={!line.length}
                        onClick={() => deliver ? savedChange() : sendReturns()}
                    >
                        <i className={`${loading ? 'fas fa-cog fa-spin' : 'fas fa-save'} mr-2`} />
                        Guardar
                    </Button>
                </div>
            </ModalBody>
        </Modal>
    )
}

export default ReceiveMaterialsModal
