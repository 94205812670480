// react
import React, { Component } from 'react';
// third-party
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { DataTable } from 'primereact/datatable';
import { Dropdown } from 'primereact/dropdown';
import { InputNumber } from 'primereact/inputnumber';
import { Column } from 'primereact/column';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Table } from 'reactstrap';
import { Tooltip } from 'primereact/tooltip';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import swal from 'sweetalert';
import moment from 'moment';
import {
    getSuggestedProductsICA,
    getStockInvPart,
    getSuggestedProductsAP,
    CreateEngprojectLine,
    DeleteEngprojectLine,
    updateProjectOnSAP,
    DeleteItems,
} from '../../store/directusSdk';
import Currency from '../shared/Currency';
import SubTable from './SubTable';
import UpdatePriority from './UpdatePriority';
import UpdateWhs from './UpdateWhs';

class ProductsTable extends Component {
    constructor(props) {
        super(props);
        this.options = [
            { label: 'P1', value: 'P1' },
            { label: 'P2', value: 'P2' },
            { label: 'P3', value: 'P3' },
            { label: 'P4', value: 'P4' },
            { label: 'S/P', value: 'S/P' },
        ];

        this.state = {
            product: null,
            cant: 1,
            warehouse: props.whs[0],
            priority: this.options[1],
            expandedRows: null,
            whsSelect: null,
            prioritySelect: null,
            filterSelect: null,
            newChanges: [],
            newChangesLines: [],
            newMovements: [],
            open: false,
            modalData: [],
            itemsInvPart: [],
            expandAll: false,
            loading: false,
            selectedItems: [],
        };
    }

    promiseOptions = (table, inputValue) => (table === 'ICA' ? getSuggestedProductsICA(inputValue) : getSuggestedProductsAP(inputValue));

    AddProduct = (product, cant, warehouse, priority) => {
        this.setState({ loading: true });
        const { table, projectId, AddLine, status } = this.props;
        return CreateEngprojectLine(table === 'ICA' ? 'engprojectlinesica' : 'engprojectlinesap', {
            product_id: product.id,
            warehouse_id: warehouse.value,
            cant_pedido: cant,
            engproject_id: projectId,
            priority,
        })
            .then((prod) => {
                if (status === 'A' && warehouse.code !== '-1') {
                    updateProjectOnSAP(projectId);
                }
                const producto = { ...product, id: prod.id, order: cant, movements: [], warehouse_id: warehouse, priority };
                this.setState({ cant: 1, product: null });
                AddLine(table, producto , projectId);
                toast.success('¡Producto agregado con Exito!');
            })
            .catch((error) => toast.error('¡Error!, ' + error.message))
            .finally(() => this.setState({ loading: false }));
    };

    expandAll = () => {
        const { products } = this.props;
        const expandedRows = {};
        products.forEach((p) => {
            expandedRows[`${p.id}`] = true;
        });

        this.setState({ expandedRows, expandAll: true });
    };

    collapseAll = () => this.setState({ expandedRows: null, expandAll: false });

    AddChange = (e, key, value) => {
        const { newChanges } = this.state;
        const { SetChanges, table } = this.props;
        const i = newChanges.findIndex((item) => item.id === e.id);

        if (i !== -1) {
            newChanges[i][`${key}`] = value;
        } else {
            const newChange = { id: e.id };
            newChange[`${key}`] = value;
            newChanges.push(newChange);
        }

        return this.setState({ newChanges }, () => SetChanges(table, newChanges));
    };

    AddChangeMovements = (e, key, value) => {
        const { newChangesLines } = this.state;
        const { SetChangesLines, table, currentUser } = this.props;
        const i = newChangesLines.findIndex((item) => item.id === e.id);

        if (i !== -1) {
            newChangesLines[i][`${key}`] = value;
            newChangesLines[i].confirmed_date = moment().format('YYYY-MM-DD HH:mm:ss');
            newChangesLines[i].confirmed_by = currentUser.id;
        } else {
            const newChange = { id: e.id };
            newChange[`${key}`] = value;
            newChange.confirmed_date = moment().format('YYYY-MM-DD HH:mm:ss');
            newChange.confirmed_by = currentUser.id;
            newChangesLines.push(newChange);
        }
        return this.setState({ newChangesLines }, () => SetChangesLines(table, newChangesLines));
    };

    AddMovement = (table, type, orderId, cant) => {
        const { newMovements } = this.state;
        const { currentUser, AddMovement } = this.props;
        const movement = { type, engprojectline_id: orderId, delivered_qty: cant, delivered_by: currentUser.id };
        const i = newMovements.findIndex((e) => e.engprojectline_id === orderId);
        if (i !== -1) {
            newMovements[i] = movement;
            return this.setState({ newMovements });
        }
        newMovements.push(movement);
        movement.delivered_by = { first_name: currentUser.first_name, last_name: currentUser.last_name };
        AddMovement(table, i, movement);
        return this.setState({ newMovements });
    };

    rowExpansionTemplate = (row) => (
        <div className="orders-subtable">
            {row.movements.length > 0 ? (
                <SubTable
                    fila={row}
                    returns={this.props.returns}
                    status={this.props.status}
                    AddChangeMovements={this.AddChangeMovements}
                    currentUser={this.props.currentUser}
                    table={this.props.table}
                    AddMovement={this.props.AddMovement}
                    products={this.props.products}
                    ChangeModalData={(modalData) => this.setState({ modalData, open: true })}
                />
            ) : (
                <p>No se Han Realizado Entregas...</p>
            )}
        </div>
    );

    Remove = (table, order) =>
        swal({
            title: 'Eliminar Pedido',
            text: `¿Desea eliminar producto con código ${order.code}?`,
            icon: 'warning',
            buttons: true,
            dangerMode: true,
        }).then((value) => {
            if (value) {
                if (table === 'ICA') {
                    return DeleteEngprojectLine('engprojectlinesica', order.id)
                        .then(() => {
                            const { products, table, RemoveLine } = this.props;
                            const productos = products.filter((e) => e.id !== order.id);
                            RemoveLine(table, productos);
                            return swal('Exito!', 'Se ha borrado el registro con exito!', 'success');
                        })
                        .catch((error) => swal('Error!', error.message, 'error'));
                }
                return DeleteEngprojectLine('engprojectlinesap', order.id)
                    .then(() => {
                        const { products, table, RemoveLine } = this.props;
                        const productos = products.filter((e) => e.id !== order.id);
                        RemoveLine(table, productos);
                        return swal('Exito!', 'Se ha borrado el registro con exito!', 'success');
                    })
                    .catch((error) => swal('Error!', error.message, 'error'));
            }
        });

    onWhsChange = (e) => {
        this.setState({ whsSelect: e.value });
        return this.dt.filter(e.value, 'warehouse_id.value', 'equals');
    };

    onPriorityChange = ({ value }) => {
        this.setState({ prioritySelect: value });
        return this.dt.filter(value, 'priority', 'equals');
    };

    onFilterChange = (e) => {
        this.setState({ filterSelect: e.value });
        return this.dt.filter(e.value, 'filter', 'equals');
    };

    CodeTemplate = (row) => (
        <React.Fragment key={row.id}>
            <Tooltip target=".detalls" />
            <span
                className="detalls"
                data-pr-tooltip={`Stock: ${row.onHand} un.\nComprometido: ${row.isCommited} un.\nEn Transito: ${row.onOrder} un.\nDisponible: ${row.available} un.`}
                data-pr-position="right"
                data-pr-at="right+5 top"
                data-pr-my="left center-2"
                style={{ cursor: 'pointer' }}
            >
                {row.code}
            </span>
        </React.Fragment>
    );

    PedidoTemplate = (row) => {
        const { status } = this.props;
        if (row.movements.length > 0 || status !== 'new') {
            return row.order;
        }
        return (
            <div style={{ width: 20 }}>
                <InputNumber
                    key={row.id}
                    inputStyle={{ width: '3em' }}
                    value={row.order}
                    onValueChange={(e) => this.AddChange(row, 'cant_pedido', e.value)}
                    min={1}
                    showButtons
                    buttonLayout="horizontal"
                    decrementButtonClassName="p-button-secondary"
                    incrementButtonClassName="p-button-info"
                    incrementButtonIcon="pi pi-plus"
                    decrementButtonIcon="pi pi-minus"
                    size={1}
                />
            </div>
        );
    };

    WarehouseTemplate = (row) => {
        const { status, whs } = this.props;

        if (row.movements.length > 0 || status === 'finished') {
            return row.warehouse_id.label;
        }
        return <UpdateWhs key={row.id} value={row.warehouse_id.value} options={whs} onChange={(val) => this.AddChange(row, 'warehouse_id', val)} />;
    };

    PriorityTemplate = (row) => {
        const { status } = this.props;
        if (row.movements.length > 0 || status === 'finished') {
            return row.priority;
        }
        return (
            <UpdatePriority
                key={row.id}
                value={this.options.find((r) => r.value === row.priority)}
                options={this.options}
                onChange={(value) => this.AddChange(row, 'priority', value)}
            />
        );
    };

    AlertTemplate = (row) => {
        if (!row.movements.length > 0 && row.warehouse_id.code === '-1') {
            if (!row.stockExternal) {
                return {
                    error: '#ea553d',
                    message: 'Item no se Encuentra  en Inventario particular del Cliente.',
                    icon: 'fas fa-exclamation-triangle fa-2x',
                };
            }
            if (row.stockExternal && row.order > row.stockExternal) {
                return {
                    error: '#ea553d',
                    message: 'Cantidad Solicitada es Mayor a la Existente en Inventario particular del Cliente.',
                    icon: 'fas fa-exclamation-triangle fa-2x',
                };
            }
        }

        if (!row.movements.length > 0) {
            return { error: '#3bc3e9', message: 'Sin Entregas.', icon: 'fas fa-exclamation-triangle fa-2x' };
        }

        for (let m of row.movements) {
            if (m.delivered_qty !== m.confirmed_qty && m.confirmed_date) {
                return {
                    error: '#ea553d',
                    message: 'Incongruencias entre cantidad Enviada y Confirmada.',
                    icon: 'fas fa-exclamation-triangle fa-2x',
                };
            }
            if (m.type === 'delivery' && m.delivered_qty > 0 && !m.confirmed_date) {
                return { error: '#ffbb44', message: 'Entregas pendientes por confirmar.', icon: 'fas fa-exclamation-triangle fa-2x' };
            }
            if (m.type === 'return' && m.delivered_qty > 0 && !m.confirmed_date) {
                return { error: '#ffbb44', message: 'Devoluciones pendientes por confirmar.', icon: 'fas fa-exclamation-triangle fa-2x' };
            }
        }

        let delivered = 0;

        row.movements.forEach((item) => {
            if (item.type === 'delivery') {
                delivered += item.confirmed_qty;
            }
        });

        if (delivered > 0 && row.order > delivered) {
            return { error: '#3bc3e9', message: 'Cantidad Recibida es menor a la orden.', icon: 'fas fa-exclamation-triangle fa-2x' };
        }

        if (delivered > 0 && delivered >= row.order) {
            return { error: '#28a765', message: 'Producto Completo!', icon: 'fa fa-check fa-2x' };
        }

        return { error: '', message: '', icon: '' };
    };

    DeleteTemplate = (row) => {
        const { status, table } = this.props;

        if (status === 'new' && !row.movements.length > 0) {
            return (
                <Button outline color="danger" title="Remover" onClick={() => this.Remove(table, row)}>
                    <i className="fa fa-trash" />
                </Button>
            );
        }

        const alert = this.AlertTemplate(row);
        if (!alert) {
            return '';
        }
        return <i key={row.id} className={alert.icon} title={alert.message} style={{ color: alert.error }} />;
    };

    exportCSV = () => {
        return this.dt.exportCSV();
    };

    showBodegas = (OnHandByWhs) => {
        let warehouses = '';
        for (const property in OnHandByWhs) {
            warehouses += `${property}: ${OnHandByWhs[property]} un.  `;
        }
        return warehouses;
    };

    showInvPart = (product, table, ruc) => {
        if (!product) {
            return this.setState({ product: null });
        }

        return getStockInvPart(product.code, table, ruc)
            .then(({ message }) => {
                product.stockExternal = message;
                return this.setState({ product });
            })
            .catch(({ code }) => {
                if (code == 203) {
                    product.stockExternal = 'Inventario Particular: No Encontrado.';
                    return this.setState({ product });
                }
            });
    };

    onSelectedItems = (e) => {
        const selectedItems = e.value.filter((i) => i.movements.length < 1);
        this.setState({ selectedItems });
    };

    DeleteAll = (table, items) =>
        swal({
            title: 'Eliminar Items Seleccionados',
            text: `¿Desea eliminar los ${items.length} Items Seleccionados?`,
            icon: 'warning',
            buttons: true,
            dangerMode: true,
            closeOnClickOutside: false,
            closeOnEsc: false,
        }).then((value) => {
            if (value) {
                const { getProject } = this.props;
                const ids = items.map((e) => e.id);
                if (table === 'ICA') {
                    return DeleteItems('engprojectlinesica', ids)
                        .then(() => {
                            getProject();
                            return swal('Exito!', 'Se han borrado los registro con exito!', 'success');
                        })
                        .catch((error) => swal('Error!', error.message, 'error'));
                }
                return DeleteItems('engprojectlinesap', ids)
                    .then(() => {
                        getProject();
                        return swal('Exito!', 'Se han borrado los registros con exito!', 'success');
                    })
                    .catch((error) => swal('Error!', error.message, 'error'));
            }
        });

    render() {
        const {
            loading,
            selectedItems,
            filterSelect,
            whsSelect,
            prioritySelect,
            expandedRows,
            cant,
            product,
            warehouse,
            expandAll,
            open,
            modalData,
            priority,
        } = this.state;
        const { whs, table, status, project, products } = this.props;

        console.log(this.props)
        if (!project.orden_venta) {
            return <h3>¡Debe Crear Orden de Venta en SAP!</h3>;
        }

        const whsFilter = <Dropdown value={whsSelect} options={whs} onChange={this.onWhsChange} placeholder="Todos..." showClear />;
        const priorityFilter = (
            <Dropdown value={prioritySelect} options={this.options} onChange={this.onPriorityChange} placeholder="Todos..." showClear />
        );
        const filter = (
            <Dropdown
                value={filterSelect}
                options={[
                    { label: 'Sin Entregas', value: 0 },
                    { label: 'Por Confirmar', value: 1 },
                    { label: 'Incompletos', value: 3 },
                    { label: 'Completos', value: 2 },
                ]}
                onChange={this.onFilterChange}
                placeholder="Todos..."
                showClear
            />
        );

        const header = (
            <React.Fragment>
                {['new', 'A'].includes(status) && (
                    <div className="row mt-2">
                        <AsyncSelect
                            cacheOptions
                            loadOptions={(e) => this.promiseOptions(table, e)}
                            defaultOptions
                            isClearable
                            noOptionsMessage={() => 'No se Encontró ningun producto.'}
                            loadingMessage={() => 'Buscando...'}
                            placeholder="Buscar producto..."
                            onChange={(producto) => this.showInvPart(producto, table, project.ruc)}
                            value={product}
                            className="col-md-5"
                        />
                        <div className="col-md-2">
                            <InputNumber
                                min={1}
                                value={cant}
                                onValueChange={(c) => this.setState({ cant: c.value })}
                                showButtons
                                buttonLayout="horizontal"
                                decrementButtonClassName="p-button-secondary"
                                incrementButtonClassName="p-button-info"
                                incrementButtonIcon="pi pi-plus"
                                decrementButtonIcon="pi pi-minus"
                                size={1}
                            />
                        </div>
                        <div className="col-md-2">
                            <Select value={warehouse} onChange={(warehouse) => this.setState({ warehouse })} options={whs} />
                        </div>
                        <div className="col-md-2">
                            <Select value={priority} onChange={(priority) => this.setState({ priority })} options={this.options} />
                        </div>
                        <Button
                            outline
                            color="success"
                            title="Agregar"
                            className="col-md-1"
                            onClick={() => this.AddProduct(product, cant, warehouse, priority.value)}
                            disabled={loading || cant < 1 || !product || !warehouse || !priority}
                        >
                            <i className={`mr-2 fas ${loading ? 'fa-cog fa-spin' : 'fa-plus-circle'} fa-lg`} />
                            Add
                        </Button>
                    </div>
                )}

                <div className="p-grid  mt-3">
                    <div className="p-col-9">
                        <div className="p-d-flex p-ai-center">
                            {expandAll ? (
                                <Button className="p-mr-3" color="warning" onClick={this.collapseAll}>
                                    <i className="fas fa-angle-double-right mr-2" />
                                    Contraer
                                </Button>
                            ) : (
                                <Button color="info" className="p-mr-3" onClick={this.expandAll}>
                                    <i className="fas fa-angle-double-down mr-2" />
                                    Expandir
                                </Button>
                            )}
                            {product && (
                                <>
                                    <span className="p-mr-3" style={{ fontSize: 14 }}>
                                        Precio: <Currency value={product ? product.price : 0} />
                                    </span>
                                    <span className="p-mr-3" style={{ fontSize: 14 }}>
                                        {this.showBodegas(product.OnHandByWhs)}
                                    </span>
                                    <span className="p-mr-3" style={{ fontSize: 14 }}>
                                        {product.stockExternal}
                                    </span>
                                </>
                            )}
                        </div>
                    </div>
                    <div className="p-col-3">
                        <div className="p-d-flex p-jc-end">
                            <Button className="p-mr-3" color="info" onClick={this.exportCSV}>
                                <i className="fas fa-file-export mr-2" />
                                Exportar
                            </Button>
                            {status === 'new' && (
                                <Button color="danger" disabled={selectedItems.length < 1} onClick={() => this.DeleteAll(table, selectedItems)}>
                                    <i className="fas fa-trash-alt mr-2" />
                                    Borrar Items
                                </Button>
                            )}
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );

        return (
            <div className="datatable-rowexpansion-demo">
                <DataTable
                    ref={(el) => (this.dt = el)}
                    value={products}
                    dataKey="id"
                    header={header}
                    selectionMode="checkbox"
                    expandedRows={expandedRows}
                    onRowToggle={(e) => this.setState({ expandedRows: e.data })}
                    rowExpansionTemplate={this.rowExpansionTemplate}
                    paginator
                    rows={10}
                    paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                    currentPageReportTemplate="Viendo {first} a {last} de {totalRecords}"
                    rowsPerPageOptions={[10, 20, 50, 100]}
                    removableSort
                    emptyMessage="No se han encontrado registros..."
                    className="p-datatable-customers"
                    selection={selectedItems}
                    onSelectionChange={this.onSelectedItems}
                >
                    {status === 'new' && <Column selectionMode="multiple" style={{ width: '2em' }} />}
                    <Column expander style={{ width: '4%' }} />
                    <Column
                        field="id"
                        header="ID"
                        style={{ width: '7%', textAlign: 'center' }}
                        sortable
                        filter
                        filterType="number"
                        filterPlaceholder="ID..."
                    />
                    <Column
                        field="code"
                        header="Código"
                        style={{ width: '10%', textAlign: 'left' }}
                        body={this.CodeTemplate}
                        sortable
                        filter
                        filterPlaceholder="Código..."
                    />
                    <Column field="sku" header="SKU" style={{ width: '13%', textAlign: 'left' }} sortable filter filterPlaceholder="SKU..." />
                    <Column
                        field="name"
                        header="Producto"
                        style={{ width: '20%', textAlign: 'justify' }}
                        sortable
                        filter
                        filterMatchMode="contains"
                        filterPlaceholder="Producto..."
                    />
                    <Column
                        field="order"
                        header="Pedido"
                        style={{ width: '11%', textAlign: 'center' }}
                        sortable
                        filter
                        filterMatchMode="gte"
                        filterPlaceholder="Mayor A..."
                        filterType="Number"
                        body={this.PedidoTemplate}
                    />
                    <Column
                        field="priority"
                        header="Prioridad"
                        style={{ width: '11%', textAlign: 'center' }}
                        sortable
                        filter
                        body={this.PriorityTemplate}
                        filterElement={priorityFilter}
                    />
                    <Column
                        field="warehouse_id.value"
                        header="Bodega"
                        style={{ width: '13%', textAlign: 'center' }}
                        sortable
                        filter
                        body={this.WarehouseTemplate}
                        filterElement={whsFilter}
                    />
                    <Column
                        field="filter"
                        header="Filtar"
                        style={{ width: '10%', textAlign: 'center' }}
                        filter
                        body={this.DeleteTemplate}
                        filterElement={filter}
                    />
                </DataTable>

                <Modal isOpen={open} toggle={() => this.setState({ open: false })} centered size="lg">
                    <ModalHeader toggle={() => this.setState({ open: false })}>Incongruencias Entre Entregas y Confirmaciones</ModalHeader>
                    <ModalBody>
                        <Table responsive>
                            <thead>
                                <tr>
                                    <th>ID</th>
                                    <th>Envio</th>
                                    <th>Confirmado</th>
                                    <th>Fecha</th>
                                    <th>Usuario</th>
                                </tr>
                            </thead>
                            <tbody>
                                {modalData.map((e, i) => (
                                    <tr key={i}>
                                        <td>{e.id}</td>
                                        <td>{e.delivered_qty}</td>
                                        <td>{e.confirmed_qty}</td>
                                        <td>{e.date}</td>
                                        <td>{e.user}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={() => this.setState({ open: false })}>
                            OK
                        </Button>
                    </ModalFooter>
                </Modal>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    currentUser: state.currentUser,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ProductsTable);
