import React, { Component, Fragment } from "react";
import { CSVReader } from "react-papaparse";
import { toast } from "react-toastify";
import { getProductsForCode } from "../../store/directusSdk";

export default class ImportCsv extends Component {
    constructor(props) {
        super(props);
        this.state = {
            errors: [],
            header: [],
        };
    }
    filterPriority = (value) => {
        if (!value) {
            return "S/P";
        }
        switch (value.toUpperCase()) {
            case "P1":
                return "P1";
            case "P2":
                return "P2";
            case "P3":
                return "P3";
            case "P4":
                return "P4";
            default:
                return " S/P";
        }

    };

    handleOnDrop = (data) => {
        const header = data.shift();
        this.setState({ errors: [], header, data });

        const codeInd = header.data.findIndex((e) => e.toLowerCase() === "codigo");
        if (codeInd === -1) {
            return toast.error("¡Error, lista de materiales sin Código de Producto!");
        }
        const quantityInd = header.data.findIndex((e) => e.toLowerCase() === "cantidad");
        if (quantityInd === -1) {
            return toast.error("¡Error, lista de materiales sin Cantidad!");
        }
        const warehouseInd = header.data.findIndex((e) => e.toLowerCase() === "bodega");
        if (warehouseInd === -1) {
            return toast.error("¡Error, lista de materiales sin Bodega!");
        }
        const priorityInd = header.data.findIndex((e) => e.toLowerCase() === "prioridad");

        const { whs, company, LoadProducts } = this.props;
        const codes = [];
        const products = [];

        data.forEach((e) => {
            if (e.data.length > 2) {
                const wh = whs.find((w) => w.name.toLowerCase() === e.data[warehouseInd].toLowerCase());
                const cant = parseInt(e.data[quantityInd]);
                const warehouse = !wh ? whs[0] : wh;
                if (!codes.includes(e.data[codeInd])) {
                    codes.push(e.data[codeInd]);
                }
                const priority = priorityInd === -1 ? "S/P" : this.filterPriority(e.data[priorityInd]);
                products.push({ cant, warehouse, code: e.data[0], priority });
            }
        });

        console.log(codes)

        getProductsForCode(company === "ICA" ? "products" : "products_ap", codes).then((productos) => {
            const prods = [];
            const errors = [];
            products.forEach((p, i) => {
                const comp = productos.find((e) => e.code === p.code);
                if (comp) {
                    prods.push({ ...p, ...comp });
                } else {
                    errors.push({ code: p.code, error: "¡Código No Encontrado!" });
                }
            });

            LoadProducts(company, prods, errors);

            return toast.info("Procesando productos...");
        });

    };

    handleOnError = (err, file, inputElem, reason) => {
        console.log(err);
    };

    handleOnRemoveFile = (data) => {
        return this.setState({ errors: [] });
    };

    render() {
        return (
            <div style={{ width: "100%", height: 200 }}>
                <CSVReader
                    onDrop={this.handleOnDrop}
                    onError={this.handleOnError}
                    addRemoveButton
                    removeButtonColor="#659cef"
                    onRemoveFile={this.handleOnRemoveFile}
                >
                    <span>Arrastra y Suelta el archivo CSV aquí o haz clic para cargarlo.</span>
                </CSVReader>
            </div>
        );
    }
}
