
import moment from 'moment';
import { toast } from 'react-toastify';
import React, { useState } from 'react';
import Button from 'reactstrap/lib/Button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { generateListForReseive } from './functions'
import { InputNumber } from 'primereact/inputnumber';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { GenerateComprobantDelivery, CreateItems, DeliveryInvetoryParticular, createMovement, UpdateItems } from '../../../store/directusSdk';

const MaterialsModal = ({ open, setOpen, project, table, currentUser, update, deliver }) => {
    const [line, setLine] = useState([]);
    const [loading, setLoading] = useState(false);

    const renderQtyDelivered = (row) => {

        return row.delivered_qty;

        if (deliver) {
            const value = row.movements.reduce((acc, current) => {
                if (current.type === "delivery") {
                    acc += current.delivered_qty;

                    return acc;
                }

                acc -= current.delivered_qty;
                return acc;
            }, 0);


            return value;
        }

        return row.movements.reduce((acc, current) => {
            if (current.confirmed_qty) {
                acc += current.confirmed_qty;

                return acc;
            }
        }, 0)
    }

    const updateLines = (row, value) => {
        setLine((e) => {
            const newState = [...e];

            const lineOld = newState.findIndex((e) => e.id === row.product.id);
            if (lineOld < 0) {

                newState.push({
                    id: row.product.id,
                    confirmed_qty: value,
                    engprojectline_id: row.product.id,
                    idLines: row.id,
                    moementId: row.moementId
                })
                return newState;
            }

            newState[lineOld] = {
                id: row.product.id,
                confirmed_qty: value,
                engprojectline_id: row.product.id,
                idLines: row.id,
                moementId: row.moementId
            };

            return newState;
        })
    }

    const renderQtyReceive = (row) => {


        return <InputNumber
            value={row.confirmed_qty ?? 0}
            min={0}
            max={renderQtyDelivered(row)}
            showButtons
            onValueChange={(e) => updateLines(row, e.value)}
            buttonLayout="horizontal"
            decrementButtonClassName="p-button-secondary"
            incrementButtonClassName="p-button-info"
            incrementButtonIcon="pi pi-plus"
            decrementButtonIcon="pi pi-minus"
            size={1}
        />
    }

    const savedChange = () => {
        setLoading(true)
        const rows = generateListForReseive(project, table, deliver);

        const sendLines = line.map((e) => ({
            type: 'delivery',
            engprojectline_id: e.engprojectline_id,
            delivered_qty: e.confirmed_qty,
            delivered_date: moment().format('YYYY-MM-DD HH:mm:ss'),
            delivered_by: currentUser.id,
            _code: e.id,
        }))

        const promises = sendLines.map((line) => {
            const code = line._code;
            delete line._code;

            const row = rows.find((r) => r.product.id === line.engprojectline_id);
            if (row.product.warehouse_code === '-1') {
                return DeliveryInvetoryParticular(table, line, project.id, code);
            }
            return createMovement(table === 'ICA' ? 'engprojectlinesmovementsica' : 'engprojectlinesmovementsap', line);
        });

        Promise.all(promises)
            .then(() => {
                update && update();
                setOpen(false);
                toast.success('¡Entrega creada con exito!');
            }).catch((error) => {
                if (error.code === '203') {
                    return toast.error('Error, Producto no Encontrado en Inventario Particular.');
                }
                return toast.error(`Error, ${error.message}`);
            })
            .finally(() => {
                setLoading(false);
            });
    }

    const sendReturns = () => {
        setLoading(true)

        const itemsForCollection = line.map((l) => ({
            id: l.moementId,
            confirmed_qty: l.confirmed_qty,
            confirmed_date: moment().format('YYYY-MM-DD HH:mm:ss'),
            confirmed_by: currentUser.id,
        }));

        Promise.all([
            UpdateItems(table === 'ICA' ? 'engprojectlinesmovementsica' : 'engprojectlinesmovementsap', itemsForCollection),
        ]).then(() => {
            update();
            setOpen(false)
            toast.success('¡Realizados con Exito!')
        })
            .catch((error) => toast.error(`¡Error!, ${error.message}`))
            .finally(() => setLoading(false));
    };

    return (
        <Modal isOpen={open} centered size="xl" zIndex={999}>
            <ModalHeader toggle={() => setOpen(false)}>
                {deliver ? 'Listado de materiales para Enviar.' :
                    'Listado de materiales para retornar.'
                }
            </ModalHeader>
            <ModalBody>
                <DataTable value={generateListForReseive(project, table, deliver)} className="p-datatable-sm">
                    <Column header="Proyecto" body={(row) => row.project.code} />
                    <Column header="Codigo" body={(row) => row.product.code} />
                    <Column header={deliver ? "Cant. Solicitada" : "Cant. Retornada"} body={renderQtyDelivered} />
                    <Column header={deliver ? "Cant. Enviar" : "Cant. Aceptar"} body={renderQtyReceive} />
                </DataTable>
                <div className='p-2' style={{ display: 'flex', justifyContent: 'end' }}>
                    <Button
                        type="button"
                        className="btn btn-primary mr-3"
                        disabled={!line.length}
                        onClick={() => deliver ? savedChange() : sendReturns()}
                    >
                        <i className={`${loading ? 'fas fa-cog fa-spin' : 'fas fa-save'} mr-2`} />
                        Guardar
                    </Button>
                </div>
            </ModalBody>
        </Modal>
    )
}

export default MaterialsModal
