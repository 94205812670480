// react
import React, { useState } from 'react';
import Button from 'reactstrap/lib/Button';
import moment from 'moment';
import { InputNumber } from 'primereact/inputnumber';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Badge } from 'reactstrap';
import NewReturn from './NewReturn';
import { getInconsistencies } from '../../store/directusSdk';


export default function SubTable({ returns, fila, AddChangeMovements, currentUser, table, AddMovement, products, status, ChangeModalData }) {
    const [loading, setLoading] = useState(false);

    const MaxInput = () => {
        let max = 0;
        let used = 0;
        fila.movements.forEach((e) => {
            if (e.type === 'return') {
                used += e.delivered_qty;
            } else {
                max += e.confirmed_qty;
            }
        });
        return (max - used);
    }

    const max = MaxInput();

    const RecibidoTemplate = (row) => {
        if (row.type === 'return' || status === 'finished' || (row.delivered_qty === row.confirmed_qty && moment(row.confirmed_date).isBefore())) {
            return row.confirmed_qty;
        }
        return <InputNumber 
        value={(row.confirmed_qty ? row.confirmed_qty : 0)} 
        min={0} 
        onValueChange={(val) => AddChangeMovements(row, 'confirmed_qty', val.value)} 
        showButtons 
        buttonLayout="horizontal" 
        decrementButtonClassName="p-button-secondary" 
        incrementButtonClassName="p-button-info" 
        incrementButtonIcon="pi pi-plus" 
        decrementButtonIcon="pi pi-minus"
         size={1} />
    }

    const ShowInconsistencies = (row) => {
        setLoading(true);
        return getInconsistencies(table === 'ICA' ? 'engprojectlinesmovementsica' : 'engprojectlinesmovementsap', row.id)
            .then((inconsistecies) => ChangeModalData(inconsistecies))
            .catch((error) => console.log(error))
            .finally(() => setLoading(false));
    }

    return (
        <React.Fragment>
            <DataTable value={fila.movements} className="p-datatable-sm">
                <Column header="Movimiento" body={(e) => <Badge color={e.type === 'delivery' ? 'info' : 'warning'}>{e.type === 'delivery' ? 'ENTREGA' : 'DEVOLUCIÓN'}</Badge>} style={{ width: '11%' }} />
                <Column field="delivered_qty" header="Cant. Enviada" style={{ width: '13%' }} />
                <Column header="Fecha" body={(e) => moment(e.delivered_date).format('DD/MM/YY hh:mm')} style={{ width: '16%' }} />
                <Column header="Responsable" body={(e) => `${e.delivered_by.first_name} ${e.delivered_by.last_name}`} style={{ width: '12%' }} />
                <Column header="Cant. Recibida" body={RecibidoTemplate} style={{ width: '14%' }} />
                <Column header="Fecha" body={(e) => (e.confirmed_date && moment(e.confirmed_date).format('DD/MM/YY hh:mm'))} style={{ width: '16%' }} />
                <Column header="Responsable" body={(e) => (e.confirmed_by && `${e.confirmed_by.first_name} ${e.confirmed_by.last_name}`)} style={{ width: '12%' }} />
                <Column header="Inc." body={(e) => (e.is_inconsistent && <Button color="warning" disabled={loading} onClick={() => ShowInconsistencies(e)} outline><i className={loading ? 'fas fa-cog fa-spin' : 'fas fa-search'} /></Button>)} style={{ width: '6%' }} />
            </DataTable>
            {max > 0 && status === 'A' && (
                <div className="row pt-2 pl-4">
                    <strong >Nueva Devolución:</strong>
                    <div className="ml-3 col-4">
                        <NewReturn max={max} returns={returns} currentUser={currentUser} fila={fila} orderId={fila.id} table={table} AddMovement={AddMovement} products={products} />
                    </div>
                </div>
            )}
        </React.Fragment>
    );
}