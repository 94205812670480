
export const generateListForReseive = (project, table, deliver) => {
    if (deliver) {
        return project.projects.reduce((acc, current) => {
            const products = table === 'ICA' ? current.productsICA : current.productsAP;

            products.forEach((p) => {

                const resto = p.movements ? p.movements.reduce((acu, current) => {
                    if (current.type === "delivery") acu += current.delivered_qty;

                    return acu;
                }, 0) : 0;

                if ((p.order - resto) > 0) {
                    acc.push({
                        delivered_qty: p.order - resto,
                        product: {
                            id: p.id,
                            code: p.code,
                            warehouse: p.warehouse_id.label,
                            warehouse_code: p.warehouse_id.code
                        }, project: {
                            code: current.mainProject.code,
                            id: current.id,
                            name: current.mainProject.name
                        }
                    })
                }
            })

            return acc;
        }, []);
    }


    return project.projects.reduce((acc, current) => {
        const products = table === 'ICA' ? current.productsICA : current.productsAP;

        products.forEach((p) => {
            if (p.movements.length) {
                p.movements.forEach((movement) => {
                    if (movement.type === "return" && !(movement.confirmed_qty === movement.delivered_qty)) {

                        acc.push({
                            moementId: movement.id,
                            delivered_qty: movement.delivered_qty,
                            confirmed_qty: movement.confirmed_qty,
                            product: {
                                id: p.id,
                                code: p.code,
                                warehouse: p.warehouse_id.label

                            }, project: {
                                code: current.mainProject.code,
                                name: current.mainProject.name
                            }
                        })
                    }
                })
            }
        })

        return acc;
    }, []);
}

